var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "fill" } },
        [
          _c("ToggleExpanded", { attrs: { refs: _vm.$refs } }),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { vertical: "", inset: "" },
          }),
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.filter ? "Press Enter to Search" : "Search Recap",
                  "prepend-inner-icon": "mdi-magnify",
                  outlined: "",
                  dense: "",
                  "background-color": "input",
                  clearable: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headersReversal,
          expanded: _vm.expanded,
          items: _vm.reversalRecap,
          "items-per-page": _vm.pageSize,
          color: "#fff",
          "loading-text": "Loading... Please wait",
          "fixed-header": "",
          dense: "",
          search: _vm.filter,
        },
        on: {
          "update:items": function ($event) {
            _vm.reversalRecap = $event
          },
          "update:itemsPerPage": function ($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function ($event) {
            _vm.pageSize = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }